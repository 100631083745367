import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import Banner from '../../Components/Banner';
import Map from '../../Components/Contact/Map';
import Message from '../../Components/Contact/Message';
import ContactCard from '../../Components/Contact/ContactCard';
import axios from 'axios';
import { Base } from '../../Baseurl/Baseurl';
import HomebannerNew from '../../Components/Home/HomebannerNew';
import Cookies from 'js-cookie'; // Import js-cookie for cookie handling

const ContactUs = () => {
  const [mapData, setMapData] = useState([]);

  // Function to fetch address data
  const addressData = () => {
    axios.get(`${Base}/api/user/view/contact`)
      .then((e) => {
        setMapData(e.data.data); // Set the map data to state
        Cookies.set('contactData', JSON.stringify(e.data.data), { expires: 7 }); // Store the response in cookies for 7 days
      })
      .catch((err) => {
        console.log(err.message); // Log error if the API call fails
      });
  };

  useEffect(() => {
    // Check if data is available in cookies
    const storedData = Cookies.get('contactData');
    if (storedData) {
      setMapData(JSON.parse(storedData)); // Use data from cookies if available
    } 
      addressData(); // If no data in cookies, fetch from the API
    
  }, []);

  return (
    <Layout>
      <HomebannerNew />
      <Banner title={"Contact Us"} />
      <ContactCard />
      <Map data={mapData} /> {/* Pass the mapData as a prop */}
      <Message />
    </Layout>
  );
};

export default ContactUs;
