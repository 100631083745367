import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RouteList } from './RouteList'
import ScrollToTop from './ScrollToTop'

const RootApp = () => {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
        {
            RouteList.map((e,i)=>{
               return <Route key={i} element={e?.element} path={e?.path} />
            })
        }
    </Routes>
    </BrowserRouter>
    </>
  )
}

export default RootApp