import React from 'react'
import Layout from '../../Layout/Layout'
import Banner from '../../Components/Banner'
import GalleryImages from '../../Components/Gallery/GalleryImages'
import HomebannerNew from '../../Components/Home/HomebannerNew'

const Gallery = () => {
  return (
    <Layout>
      <HomebannerNew />
<Banner title={"Gallery"} />
<div className='container m-auto'>

<GalleryImages/>
</div>
    </Layout>
  )
}

export default Gallery