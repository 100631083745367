import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './style.css'; // Add your custom styles here.

const HomebannerNew = () => {
  return (
    <div className="custom-ui">
      <Container>
        <Row className="align-items-end text-center text-md-left">
          {/* Left Section */}
          <Col md={3} xs={12} className="mb-4 mb-md-0 d-flex flex-column align-items-center align-items-md-start">
            {/* Social Media Icons */}

            {/* Order Button */}

            <Button variant="dark" className="mb-3 w-100">
              <a href="https://order.spoton.com/so-ole-brass-rail-19042/phoenix-az/662c36f0f52c5a003ca8c84f" target="_blank" rel="noopener noreferrer">

                ORDER HERE
              </a>
            </Button>
            {/* QR Code */}
            <Image src="./assets/img/my/scaner.webp" alt="QR Code" fluid style={{ margin: "auto" }} />
          </Col>

          {/* Center Section */}
          <Col md={6} xs={12} className="text-center">
            {/* Logo */}
            <Image
              src="./assets/img/my/newlogo.webp"
              alt="Ole Brass Rail Logo"
              fluid
              style={{}}
              className="mb-3"
            />
            {/* Main Heading */}
            {/* <h1 className='headingbanner'>PHOENIX, ARIZONA</h1> */}
            {/* Subtitle */}
            <p className="subtitle   newfont">
              Your destination for sports, drinks, delicious food, and fun.
            </p>
            {/* Contact Info */}
            <p className="contact-info">602-956-1280</p>
          </Col>

          {/* Right Section */}
          <Col md={3} xs={12} className="mb-4 mb-md-0 d-flex flex-column align-items-center align-items-md-center">
            {/* WiFi Icon */}
            <div className="mb-3">
              <Image src="./assets/img/my/wifi.webp" alt="Free Wifi" fluid style={{}} />
            </div>
            {/* Turf Paradise Icon */}
            <a href="https://www.turfparadise.com/" target="_blank" rel="noopener noreferrer">

              <div className='d-flex gap-2 m-auto'>

                <div className="mb-3">
                  <a href="https://www.instagram.com/olebrassrailaz/" target="_blank" rel="noopener noreferrer">
                    <Image src="./assets/img/my/insta.webp" alt="Facebook" fluid />
                  </a>
                </div>
                <div className="mb-3">
                  <a href="https://www.facebook.com/profile.php?id=61567674051108" target="_blank" rel="noopener noreferrer">
                    <Image src="./assets/img/my/face.png" alt="Facebook" fluid />
                  </a>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomebannerNew;
