/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';

const Map = ({ data }) => {
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    setMapData(data);
  }, [data]);

  return (
    <div className="map-area inner-padding3 container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4 pn">
            <div className="text-center" style={{ color: 'black' }}>
              <h1 className="text-center py-4" style={{ color: 'black' }}>
                Address
              </h1>
              <p style={{ color: 'black', fontWeight: 'bold' }}>
                {mapData?.address ? mapData?.address : '3738 E Thomas Rd​ Phoenix, AZ. 85018'}
              </p>
              <p style={{ color: 'black', fontWeight: 'bold' }}>
                PHONE : {mapData?.phone_no ? mapData?.phone_no : '602- 956-1280'}
              </p>
              <p style={{ color: 'black', fontWeight: 'bold' }}>
                E-MAIL : {mapData?.email ? mapData?.email : 'olebrassrail@yahoo.com'}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8 pn">
            <div className="map-contacts">
               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d831.9473846623304!2d-112.0008614!3d33.4808298!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0db5325e10e7%3A0xdc69a0289677bcda!2s3738%20E%20Thomas%20Rd%2C%20Phoenix%2C%20AZ%2085018%2C%20USA!5e0!3m2!1sen!2s!4v1734130778541!5m2!1sen!2s" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
