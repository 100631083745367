import React from 'react'
import Layout from '../../Layout/Layout'
import Banner from '../../Components/Banner'
import DailySpecialsSlider from '../../Components/DailySpecialsSlider/DailySpecialsSlider'
import HomebannerNew from '../../Components/Home/HomebannerNew'

const DailySpecials = () => {
  return (
   <Layout>
  <HomebannerNew/>
<Banner title={"Daily Specials"} />
<DailySpecialsSlider/>
   </Layout>
  )
}

export default DailySpecials