import BreakFast from "../pages/BreakFast/BreakFast";
import ContactUs from "../pages/ContactUs/ContactUs";
import DailySpecials from "../pages/DailySpecials/DailySpecials";
import Event from "../pages/Event/Event";
import Gallery from "../pages/Gallery/Gallery";
import { Home } from "../pages/Home/Home";
import LunchDinner from "../pages/LunchDinner/LunchDinner";


export const RouteList = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/events',
        element: <Event />
    },{
        path: '/gallery',
        element: <Gallery />
    },{
        path: '/breakfast_menu',
        element: <BreakFast />
    },{
        path: '/lunch_dinner',
        element: <LunchDinner />
    },{
        path: '/daily_specials',
        element: <DailySpecials />
    },{
        path: '/contact_us',
        element: <ContactUs />
    }
]