/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import './styles.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Base } from '../../Baseurl/Baseurl';
import Cookies from 'js-cookie'; // Import Cookies to handle cookie operations

const BreakFastSlider = () => {
  const [breakfast, setBreakFast] = useState([]);

  const [breakfast2, setBreakFast2] = useState([  // Fallback data for when no breakfast data is available
    {
      image: "./assets/img/my/longtypebold.png"
    }, {
      image: "./assets/img/my/longtypebold.png"
    }, {
      image: "./assets/img/my/longtypebold.png"
    }, {
      image: "./assets/img/my/longtypebold.png"
    }
  ]);

  // Function to fetch data from API and set it in cookies
  const Img = () => {
    axios.get(`${Base}/api/user/view_breakfast_menu`)
      .then((e) => {
        if(e){
          // setBreakFast(e.data.data); // Set fetched data to state
          Cookies.set('breakfastMenu', JSON.stringify(e.data.data), { expires: 1 }); // Store data in cookies for 1 day
        }
      })
      .catch((err) => {
        console.log(err); 

      });
  };

  useEffect(() => {
    // Check if breakfast data is already stored in cookies
    const storedData = Cookies.get('breakfastMenu');
    if (storedData) {
      console.log(storedData);
      
      setBreakFast(JSON.parse(storedData)); // If data exists in cookies, use it
    } 
      Img(); // If no data in cookies, make an API call
    
  }, []); // Only run on mount


  return (
    <div className='container my-5'>
      <div className="section-title-area">
        <h2 className="section-title" style={{color:'black'}}>Breakfast Menu</h2>
        <div className="section-divider" />
      </div>

      {
        breakfast.length == 1 ? (
          <div>
            {(breakfast.length > 0 ? breakfast : breakfast2).map((img, index) => {
              return (
                <div key={index} style={{
                  display:'flex',
                  justifyContent:"center",
                  margin:"auto"
                }}>
                  
                  <img src={img.image} alt="" style={{width:'50%', margin:"auto"}} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className='breakfast-slider'>
            {(breakfast.length > 0 ? breakfast : breakfast2).map((img, index) => {
              return (
                <div key={index} style={{ margin:"auto" }}>

                  <img src={img.image} alt="" />
                </div>
              );
            })}
          </div>
        )
      }
    </div>
  );
};

export default BreakFastSlider;
